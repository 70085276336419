import { Button, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocal } from '../../../context/local/LocalContext'
import { useUi } from '../../../context/ui/UIContext'
import './SelecionarLogin.css'

const SelecionarPagamento = () => {
    const { push, replace } = useHistory();
    const { isIframe } = useUi();
    const { localLoading, storeConfig } = useLocal();

    useEffect(() => {
        if (storeConfig) {
            if (!storeConfig.isMeepCard) {
                replace("/payment")
            }
        }
        return () => {

        }
    }, [replace, storeConfig])

    return (
        <Grid spacing={2} container>
            {
                !localLoading &&
                <>
                    <Grid xs={12} item>
                        <Button fullWidth variant="contained" color="primary" size="large" onClick={() => push(`/paymentSchool`)}>Cartão Aluno</Button>
                    </Grid>
                    {
                        (storeConfig?.allowOnlinePayment || storeConfig?.allowPaymentOnDelivery) &&
                        <Grid xs={12} item>
                            <Button fullWidth variant="contained" color="primary" size="large" onClick={() => push(`payment`)}>Outras formas de pagamento</Button>
                        </Grid>
                    }
                </>
            }
            {isIframe && <Grid item xs={12}>
                <Button
                    onClick={() => { window.parent.postMessage({ command: "CLOSE" }, "*") }}
                    fullWidth size="large"
                    variant="contained"
                >
                    {"VOLTAR"}
                </Button>
            </Grid>}
        </Grid>
    )
}

export default SelecionarPagamento
